import { inputBaseClasses, Paper } from '@mui/material';
import { keyframes, styled } from '@mui/material/styles';
import { PickersDay } from '@mui/x-date-pickers';

const appear = keyframes`
   from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
`;

export const Wrapper = styled('div', {
  shouldForwardProp: prop => !['fullWidth'].includes(prop),
})(({ theme, fullWidth }) => ({
  position: 'relative',
  minWidth: 145,
  flexGrow: 1,
  width: fullWidth ? '100%' : 145,
  [`.${inputBaseClasses.root}`]: {
    backgroundColor: theme.palette.grayscale[100],
  },
}));

export const CalendarWrapper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  overflow: 'hidden',
  animationName: appear,
  animationDuration: '0.3s',
  zIndex: theme.zIndex.popper,
  borderRadius: theme.shape.borderRadiusLarge,
  transform: `translateY(-${theme.spacing(1)})`,
  '& .MuiYearCalendar-root': {
    width: '100%',
  },
}));

export const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: prop =>
    prop !== 'isSelected' &&
    prop !== 'isHovered' &&
    prop !== 'daysSinceStartOfWeek',
})(({ theme, isSelected, isHovered, daysSinceStartOfWeek }) => ({
  borderRadius: 0,
  ...(isHovered && {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.text.primary,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.light,
    },
  }),
  ...(isSelected && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.main,
    },
  }),
  ...(daysSinceStartOfWeek === 0 && {
    borderTopLeftRadius: '25%',
    borderBottomLeftRadius: '25%',
  }),
  ...(daysSinceStartOfWeek === 6 && {
    borderTopRightRadius: '25%',
    borderBottomRightRadius: '25%',
  }),
}));
